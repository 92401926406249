const request = {
  data() {
    return {
      searchForm: {},
      dataList: [],
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      reqFunc: null,
      searchLoding: false,
    }
  },
  methods: {
    onReset() {
      this.searchForm = {}
      this.page.current = 1
      this.getData()
    },
    onSearch() {
      this.page.current = 1
      this.getData()
      this.searchLoding = true
    },
    getData(fun) {
      fun ??= this.reqFunc
      this.reqFunc = fun
      fun?.({
        data: { ...this.searchForm },
        pageNumber: this.page.current,
        pageSize: this.page.size,
      }).then((res) => {
        this.page.total = +res.data.total
        this.dataList = res.data.content || []
        this.searchLoding = false
      })
    },
    handleSizeChange(val) {
      this.page.size = val
      this.getData()
    },
    handleCurrentChange() {
      this.getData()
    },
  },
}

export default request
