<template>
  <div class="shed-space">
    <div class="search">
      <div class="search-item">
        <div class="search-item__label">类型：</div>
        <el-input
          v-model="searchForm.productCodeOrName"
          size="small"
          placeholder="输入类型ID/名称"
          style="width: auto"
        ></el-input>
      </div>
      <!-- <div class="search-item">
        <div class="search-item__label">状态：</div>
        <el-select v-model="searchForm.isDisable" size="small" placeholder="选择状态">
          <el-option label="启用" :value="0"></el-option>
          <el-option label="停用" :value="1"></el-option>
        </el-select>
      </div> -->
      <div class="search-button">
        <el-button type="" size="small" @click="onSearch">查询</el-button>
        <el-button type="" size="small" @click="onReset">重置</el-button>
        <el-button type="primary" size="small" @click="openCreate">配置棚位类型</el-button>
      </div>
    </div>
    <el-table :data="dataList" border>
      <el-table-column label="产品ID" prop="productCode" align="center"></el-table-column>
      <el-table-column label="产品名称" prop="productName" align="center"></el-table-column>
      <el-table-column label="档期(分钟)" prop="schedule" align="center"></el-table-column>
      <el-table-column label="状态" prop="isDisable" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.isDisable == 0 ? 'success' : 'info'">{{
            scope.row.isDisable == 0 ? '启用' : '停用'
          }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="handleEnable(scope.row)" type="text" size="small">{{ scope.row.isDisable == 1 ? '启用' : '停用' }}</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page.sync="page.current"
        :page-size="page.size"
        background
        :page-sizes="[10, 20, 30, 40]"
        layout="prev, pager, next, sizes, jumper"
        :total="page.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <el-dialog title="配置棚位类型" :visible.sync="dialogFormVisible" width="400px">
      <el-form ref="shedSpaceForm" :model="shedSpaceForm" :rules="shedSpaceRules">
        <el-form-item label="棚位类型：" label-width="100px" prop="productId">
          <el-select v-model="shedSpaceForm.productId" size="small" placeholder="选择棚位类型">
            <el-option
              v-for="(item, index) in shedTypeListAll"
              :key="index"
              :label="item.productName"
              :value="item.productId"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否启用：" label-width="100px" prop="isDisable">
          <el-radio-group v-model="shedSpaceForm.isDisable">
            <el-radio :label="0">直接启用</el-radio>
            <el-radio :label="1">暂时不启用</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" :loading="formLoading" @click="onConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import request from '@/mixins/request.js'
import {
  getShedTypeList,
  saveShedType,
  updateEnable,
  getShedTypeListAll,
} from '@/api/shed-space.js'
export default {
  mixins: [request],
  data() {
    return {
      shedSpaceForm: {
        isDisable: 0,
        productId: '',
      },
      dialogFormVisible: false,
      shedSpaceRules: {
        productId: [{ required: true, message: '请选择棚位类型', trigger: 'change' }],
        isDisable: [{ required: true, message: '请选择是否启用', trigger: 'change' }],
      },
      shedTypeListAll: [],
      formLoading: false,
    }
  },
  mounted() {
    this.getData(getShedTypeList)
  },
  methods: {
    getShedTypeAll() {
      getShedTypeListAll().then((res) => {
        console.log(res)
        this.shedTypeListAll = res.data
      })
    },
    openCreate() {
      this.getShedTypeAll()
      this.dialogFormVisible = true
    },
    handleEdit(row) {
      this.shedSpaceForm = row
      this.dialogFormVisible = true
    },
    onConfirm() {
      const form = JSON.parse(JSON.stringify(this.shedSpaceForm))
      const { productCode, productName, schedule } = this.shedTypeListAll.find(
        (e) => e.productId === form.productId,
      )
      form.productCode = productCode
      form.productName = productName
      form.schedule = schedule
      this.formLoading = true
      saveShedType({ data: form })
        .then(() => {
          this.formLoading = false
          this.$message.success('添加成功！')
          this.onClose()
          this.getData()
        })
        .catch((e) => {
          this.formLoading = false
          this.$message.error(e.description || '添加失败！')
        })
    },
    onClose() {
      this.dialogFormVisible = false
      this.shedSpaceForm = {
        specifications: 30,
      }
      this.$refs.shedSpaceForm.resetFields()
    },
    async confirm(e) {
      return await this.$confirm(`是否确认${e}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
    },
    async handleEnable({ shedTypeId, isDisable }) {
      await this.confirm(isDisable === 1 ? '启用' : '停用')
      updateEnable({ data: shedTypeId })
        .then((res) => {
          console.log(res)
          this.getData()
          this.$message.success(isDisable === 1 ? '启用成功!' : '停用成功！')
        })
        .catch((e) => {
          this.$message.error(e.description || (isDisable === 1 ? '启用失败!' : '停用失败！'))
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.shed-space {
  padding: 12px;
  background-color: #fff;
}
.search {
  display: flex;
  margin-bottom: 24px;
  &-item {
    display: flex;
    align-items: center;
    margin-right: 24px;
    &__label {
      font-size: 13px;
      width: 60px;
    }
  }
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
</style>
