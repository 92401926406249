import request from '@/utils/request'

/** 棚位类型 */
export function getShedTypeList(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/type/pageList',
    method: 'POST',
    data,
  })
}
export function saveShedType(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/type/save',
    method: 'POST',
    data,
  })
}
export function updateEnable(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/type/enable',
    method: 'POST',
    data,
  })
}

/** 棚位列表 */
export function getShedList(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/pageList',
    method: 'POST',
    data,
  })
}
export function saveShed(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/save',
    method: 'POST',
    data,
  })
}
export function updateShedListEnable(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/enable',
    method: 'POST',
    data,
  })
}
/** 运营端棚位类型的产品列表 */
export function getShedTypeListAll(data) {
  return request({
    url: 'product-web-api/supplier/product/shedList',
    method: 'POST',
    data,
  })
}
/** 棚位类型的全部列表 */
export function getShedTypeMapAll(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/type/list',
    method: 'POST',
    data,
  })
}
/** 棚位预约时间段 */
export function getReserveDetail(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/reserveDetail',
    method: 'POST',
    data,
  })
}
/** 棚位停、启用 */
export function updateStatus(data) {
  return request({
    url: 'supplier-web-api/supplier/store/shed/updateStatus',
    method: 'POST',
    data,
  })
}
/** 查询ERP员工 */
export function searchemp(data) {
  return request({
    url: 'product-web-api/api/erp/searchemp',
    method: 'POST',
    data,
  })
}
/** 查询ERP棚位 */
export function searchSeat(data) {
  return request({
    url: 'product-web-api/api/erp/searchSeat',
    method: 'POST',
    data,
  })
}

/** 门店列表 */
export function getStoreList(data) {
  return request({
    url: 'supplier-web-api/admin/store/shed/matchingAndStoreList',
    method: 'POST',
    data,
  })
}
